import { atom, selector } from 'recoil';
import {
  NOTIFICATION_SETTINGS,
  COUNTRY_DATA,
  REFRESH_TABLE_GENERATOR_DATA,
  UNSUBSCRIBE_PLAN,
} from './constants';
import { getCountries } from '../api/loginRegister';

/**
 * Store notification info
 */
export const notificationAtom = atom({
  key: NOTIFICATION_SETTINGS,
  default: {
    open: false,
    severity: 'success',
    message: '',
  },
});

export const getCountrySelector = selector({
  key: COUNTRY_DATA,
  get: async () => {
    const response = await getCountries();

    return response;
  },
});

export const refreshTableGeneratorDataAtom = atom({
  key: REFRESH_TABLE_GENERATOR_DATA,
  default: 0,
});

export const unsubscribePlanAtom = atom({
  key: UNSUBSCRIBE_PLAN,
  default: 0,
});
