import apiClient from './apiClient';

// eslint-disable-next-line import/prefer-default-export
export function submitCouponCode(code) {
  const data = {
    couponCode: code,
  };

  return apiClient.post('user/addcoupon', data);
}
