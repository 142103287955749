import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function AmberText(props) {
  const {
    text,
    textAlign = 'left',
    noWrap = false,
    color = 'unset',
    testid = '',
    marginTop = 1,
    marginBottom = 1,
    lineHeight = '1.5rem',
  } = props;

  return (
    <Box
      data-testid={testid}
      component="span"
      marginTop={marginTop}
      marginBottom={marginBottom}
      textAlign={textAlign}
      whiteSpace={noWrap ? 'nowrap' : 'normal'}
      color={color || 'unset'}
      lineHeight={lineHeight}
    >
      <Typography variant="normal">{text}</Typography>
    </Box>
  );
}

AmberText.propTypes = {
  text: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  color: PropTypes.string,
  noWrap: PropTypes.bool,
  testid: PropTypes.string,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  lineHeight: PropTypes.string,
};

AmberText.defaultProps = {
  textAlign: 'left',
  noWrap: false,
  color: 'unset',
  testid: '',
  marginTop: 1,
  marginBottom: 1,
  lineHeight: '1,5rem',
};
