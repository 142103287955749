import { atom } from 'recoil';
import { REDEEM_COUPON_DIALOG_ATOM } from './constants';

// Atoms
// eslint-disable-next-line import/prefer-default-export
export const viewRedeemCouponDialogAtom = atom({
  key: REDEEM_COUPON_DIALOG_ATOM,
  default: {
    open: false,
  },
});
