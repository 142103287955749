/**
 * Gets param from URL by key
 * @param {string} key
 * @returns param value
 */
export function getParamValueByKey(key) {
  const fullUrl = window.location.href;
  const url = new URL(fullUrl);
  const value = url.searchParams.get(key);
  return value;
}

/**
 * Removes the search params fromm the URL
 */
export function removeParamsFromUrl() {
  window.history.replaceState(null, null, window.location.pathname);
}

/**
 * Prevents the user from going back
 */
export function preventGoingBackInHistory() {
  window.history.pushState(null, document.title, window.location.href);
  window.addEventListener('popstate', () => {
    window.history.pushState(null, document.title, window.location.href);
  });
}

/**
 * Opens a new tab with the given url
 * @param {string} url
 */
export function openTab(url) {
  const win = window.open(url, '_blank');
  win.focus();
}
