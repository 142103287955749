export const SERVICE_TRANSCRIPTION = 'SERVICE_TRANSCRIPTION';
export const SERVICE_SUBTITLES = 'SERVICE_SUBTITLES';

export const SERVICE_AUTOMATIC = 'SERVICE_AUTOMATIC';
export const SERVICE_MANUAL = 'SERVICE_MANUAL';

export const SERVICE_TOP_UP_BALANCE = 'SERVICE_TOP_UP_BALANCE';
export const SERVICE_FILE_CUTTING = 'SERVICE_FILE_CUTTING';
export const SERVICE_UPGRADE_TO_SUBSCRIPTION =
  'SERVICE_UPGRADE_TO_SUBSCRIPTION';
export const SERVICE_UPGRADE_TO_SUBSCRIPTION_MONTHLY =
  'SERVICE_UPGRADE_TO_SUBSCRIPTION_MONTHLY';
export const SERVICE_UPGRADE_TO_SUBSCRIPTION_YEARLY =
  'SERVICE_UPGRADE_TO_SUBSCRIPTION_YEARLY';

// ACTIONS
export const CHANGE_SELECTED_SERVICE = 'CHANGE_SELECTED_SERVICE';
export const CHANGE_SERVICE_TYPE = 'CHANGE_SERVICE_TYPE';
export const CHANGE_TOP_UP_BALANCE_OR_SUBSCRIBE =
  'CHANGE_TOP_UP_BALANCE_OR_SUBSCRIBE';
export const CHANGE_TOP_UP_BALANCE_NR_HOURS = 'CHANGE_TOP_UP_BALANCE_NR_HOURS';
export const TOGGLE_SERVICE_MANUAL_VERBATIM = 'TOGGLE_SERVICE_MANUAL_VERBATIM';
export const TOGGLE_SERVICE_MANUAL_BURN_IN_CAPTIONS =
  'TOGGLE_SERVICE_MANUAL_BURN_IN_CAPTIONS';
export const TOGGLE_SERVICE_MANUAL_TRANSLATION =
  'TOGGLE_SERVICE_MANUAL_TRANSLATION';
export const ADD_SELECTED_LANGUAGES_FOR_TRANSLATION =
  'ADD_SELECTED_LANGUAGES_FOR_TRANSLATION';
export const REMOVE_SELECTED_LANGUAGES_FOR_TRANSLATION =
  'REMOVE_SELECTED_LANGUAGES_FOR_TRANSLATION';
export const TOGGLE_UPGRADE_TO_SUBSCRIPTION = 'TOGGLE_UPGRADE_TO_SUBSCRIPTION';
export const TOGGLE_SUBSCRIPTION_TYPE = 'TOGGLE_SUBSCRIPTION_TYPE';
export const TOGGLE_SERVICE_MANUAL_RUSH_ORDER =
  'TOGGLE_SERVICE_MANUAL_RUSH_ORDER';

export const TOGGLE_SERVICE_MANUAL_TRANSLATED_RUSH_ORDER =
  'TOGGLE_SERVICE_MANUAL_TRANSLATED_RUSH_ORDER';

export const RESET_SERVICE_MANUAL_TRANSLATED_RUSH_ORDER =
  'RESET_SERVICE_MANUAL_TRANSLATED_RUSH_ORDER';
export const DISABLE_TARGET_LANGUAGE = 'DISABLE_TARGET_LANGUAGE';

// SERVICE TYPES
export const SERVICE_TYPE_TRANSCRIPTION = 'transcription';
export const SERVICE_TYPE_SUBTITLES = 'captions';
export const SERVICE_TYPE_TRANSLATED_SUBTITLES = 'translatedSubtitles';

// TRANSCRIPTION STYLES
export const TRANSCRIPTION_STYLE_CLEANREAD = 'cleanread';
export const TRANSCRIPTION_STYLE_VERBATIM = 'verbatim';

// TURNAROUND TIMES
export const TURNAROUND_TIME_ONE_DAY = 'ONE_DAY';
export const TURNAROUND_TIME_TWO_DAYS = 'TWO_DAYS';
export const TURNAROUND_TIME_THREE_DAYS = 'THREE_DAYS';
export const TURNAROUND_TIME_FOUR_DAYS = 'FOUR_DAYS';
export const TURNAROUND_TIME_FIVE_DAYS = 'FIVE_DAYS'; //DEFAULT
export const TURNAROUND_TIME_SIX_DAYS = 'SIX_DAYS';
export const TURNAROUND_TIME_SEVEN_DAYS = 'SEVEN_DAYS';
export const TURNAROUND_TIME_EIGHT_DAYS = 'EIGHT_DAYS';

// PRIVLIGES
export const AUTOMATIC_UNLIMITED = 'AUTOMATIC_UNLIMITED';
export const AUTOMATIC_MONTHLY = 'AUTOMATIC_MONTHLY';
export const MANUAL_CAPTIONS_AND_TRANSCRIPTIONS =
  'MANUAL_CAPTIONS_AND_TRANSCRIPTIONS';

// TURNAROUND TIMES TO DISPLAY
export const MINIMUN_TURN_AROUND_TIME = 5;
