import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export default function AmberRootButton(props) {
  const { children } = props;

  return (
    <Box padding={1} marginTop={1} marginBottom={1}>
      {children}
    </Box>
  );
}

AmberRootButton.propTypes = {
  children: PropTypes.node.isRequired,
};
