/**
 * Init profitwell for the user
 * @param {string} userName Username of the user
 */
// eslint-disable-next-line no-unused-vars
export default function SetupProfitwell(userName) {
  // if (profitwell) {
  //   profitwell('start', { user_email: userName });
  // }
}
