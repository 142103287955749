/* eslint-disable */
import axios from 'axios';
import { storeItemInLocalStorage } from './storage';

export function setupGTM(w, d, s, l, i) {
  // TAG MANAGER FOR STAGING
  if (process.env.NODE_ENV === 'development') {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' +
      i +
      dl +
      '&gtm_auth=c5NN4RkutjxQig2dZdGtDw&gtm_preview=env-115&gtm_cookies_win=x';
    f.parentNode.insertBefore(j, f);
  }
  // TAG MANAGER FOR PRODUCTION
  else {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  }

  window.dataLayer = window.dataLayer || [];
  let gtag = function () { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', i, { 'optimize_id': process.env.REACT_APP_OPTIMIZE_ID});
}

/**
 * Retrieve datalayer data from server
 *
 * @return {Promise}
 */
function retrieveDatalayer() {
  const baseURL = `${process.env.REACT_APP_BE_BASE_URL}/v2/`;
  return axios.get(`${baseURL}analytics/datalayer`, {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
}

/**
 * Set datalayer
 *
 * @param {Object} data
 */
function setDataLayer(data) {
  storeItemInLocalStorage('analyticsDL', JSON.stringify(data));
  window.dataLayer.push(data);
}

/**
 * Get datalayer
 *
 * @return {Promise}
 */
export function initDataLayer() {
  try {
    const request = retrieveDatalayer().then((response) => {
      let output = {};

      for (let i = 0; i < response.data.length; i++) {
        let { label, value } = response.data[i];
        output[label] = value;
      }

      setDataLayer(output);
    })
    .finally(() => {
      return request;
    })
  } catch (error) {
    // TODO: Sentry
  }
}
