import React from 'react';
import { Fade } from '@mui/material';

function CustomTransition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Fade ref={ref} {...props} />;
}
const FadeComponent = React.forwardRef(CustomTransition);

export default FadeComponent;
