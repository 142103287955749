import axios from 'axios';
import apiClient from './apiClient';

export function login() {}

/**
 * Get user object, if not authenticated redirects to login page
 */
export function getUser() {
  return apiClient.get('user/get');
}

/**
 * Get user object, if not authenticated throws
 */
export function getUserUnsafe() {
  const baseURL = `${process.env.REACT_APP_BE_BASE_URL}/v2`;

  return axios.get(`${baseURL}/user/get`, {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
}

/**
 * Get admin users
 */
export function getAdminUsers(paginationOptions, cancelToken, searchTerm) {
  const { page, pageSize, sortProperty, sortDirection, exactMatch } =
    paginationOptions;

  return apiClient.get('admin/users', {
    params: {
      search: searchTerm || undefined,
      page: page || 0,
      pageSize: pageSize || 10,
      sortProperty: sortProperty || 'updated',
      sortDirection: sortDirection || 'desc',
      exactMatch: exactMatch || false,
    },
    cancelToken: cancelToken.token,
  });
}

/**
 * Activate user
 * @param {string} userName
 */
export function activateUser(userName) {
  return apiClient.post('admin/user/activate', {
    userName,
  });
}

/**
 * Pause user
 * @param {string} userName
 * @returns
 */
export function pauseUser(userName) {
  return apiClient.post('admin/user/pause', {
    userName,
  });
}

/**
 * Get user by userName
 * @param {string} userName
 * @returns
 */
export function getUserByUsername(userName) {
  return apiClient.get('user/get', {
    params: {
      userName,
    },
  });
}

/**
 * Update user object
 */
export function updateUser(userData) {
  const {
    email,
    phone,
    firstName,
    lastName,
    city,
    countryCode,
    language,
    sectorCode,
    optInDataForTraining,
    optInDataForMarketing,
    userName,
  } = userData;

  const data = {
    countryCode,
    firstName,
    language,
    lastName,
    sectorCode,
    userName: email || userName,
    city,
    phone,
    optInDataForTraining: process.env.REACT_APP_FF_GDPR_FIELDS
      ? optInDataForTraining
      : undefined,
    optInDataForMarketing: process.env.REACT_APP_FF_GDPR_FIELDS
      ? optInDataForMarketing
      : undefined,
  };

  return apiClient.post('/user/update', data);
}

/**
 * Update user password
 */
export function updateUserPassword(userData) {
  const { password, email } = userData;
  const data = { password, userName: email };

  return apiClient.post('/user/update', data);
}

/**
 * Delete user object
 */
export function deleteUser(userData) {
  const { password, email } = userData;

  const data = {
    userName: email,
    password: password || undefined,
  };

  return apiClient.post('/user/delete', data);
}

/**
 * Resend welcome email
 */
export function resendWelcomeEmail() {
  return apiClient.post('/user/resendwelcomeemail');
}
