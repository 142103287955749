import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '../Transitions/Fade';

export default function AmberCustomDialog(props) {
  const {
    open,
    handleClose,
    title,
    dialogContent,
    dialogAction,
    maxWidth,
    btnX = false,
    testId,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Fade}
      keepMounted
      maxWidth={maxWidth}
      fullWidth
      data-testid={testId}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="header">{title}</Typography>
          </Box>
          {btnX && (
            <Box>
              {/* close button */}
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box pt={1}>{dialogContent}</Box>
      </DialogContent>
      <DialogActions>{dialogAction}</DialogActions>
    </Dialog>
  );
}

AmberCustomDialog.propTypes = {
  btnX: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dialogContent: PropTypes.node.isRequired,
  dialogAction: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  testId: PropTypes.string,
};

AmberCustomDialog.defaultProps = {
  btnX: false,
  maxWidth: 'sm',
  testId: '',
};
