import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export default function setupSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    environment:
      process.env.REACT_APP_ENVIRONMENT === 'development'
        ? 'staging'
        : 'production',
    tracesSampleRate: 1.0,
  });
}
