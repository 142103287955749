import { useSetRecoilState } from 'recoil';
import { notificationAtom } from '../../store/common';

export default function useNotification() {
  const setNotification = useSetRecoilState(notificationAtom);

  function showNotification(severity, message) {
    setNotification({
      open: true,
      severity,
      message,
    });
  }

  return [showNotification];
}
