/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import ReactGA from 'react-ga4';
import {
  SERVICE_AUTOMATIC,
  SERVICE_MANUAL,
  SERVICE_SUBTITLES,
  SERVICE_TRANSCRIPTION,
  TURNAROUND_TIME_ONE_DAY,
  TURNAROUND_TIME_THREE_DAYS,
} from '../components/User/Uploads/newUploadForm/states/constants/services';
import {
  GA_AUTOMATIC,
  GA_MANUAL,
  TRANSLATED_SUBTITLE_ID,
  AUTOMATIC_TRANSCRIPTION_ID,
  MANUAL_TRANSCRIPTION_ID,
  AUTOMATIC_SUBTITLE_ID,
  MANUAL_SUBTITLES_ID,
  GA_EVENT_UPLOAD_FILE,
  GA_EVENT_EXPORT_FILE,
  GA_OPTION_VERBATIM,
  GA_OPTION_RUSH,
  GA_TRANSLATED_SUBTITLES,
  GA_TRANSLATED_TRANSCRIPTION,
  GA_TRANSLATED_CAPTIONS,
  GA_TRANSLATED_JUST_SUBTITLES,
} from './constants';
import { getCookie } from './cookie';

export function googleAnalyticsFireEvent(
  eventType,
  eventCategory,
  additionalParams = {},
) {
  ReactGA.gtag('event', eventType, {
    event_category: eventCategory,
    ...additionalParams,
  });
}

export function googleAnalyticsSetUserProperties(params) {
  ReactGA.gtag('set', {
    ...params,
  });
}

export function fireGAUpdateUserRegister(
  sector,
  interestedIn,
  volume,
  company,
) {
  const products = [];
  if (interestedIn) {
    if (interestedIn.automatic_subtitle === true) {
      products.push('as');
    }
    if (interestedIn.manual_subtitle === true) {
      products.push('ms');
    }
    if (interestedIn.translated_subtitle === true) {
      products.push('ts');
    }
    if (interestedIn.automatic_transcription === true) {
      products.push('at');
    }
    if (interestedIn.manual_transcription === true) {
      products.push('mt');
    }
  }
  const productsString = products.join(', ');

  googleAnalyticsSetUserProperties({
    sector: sector || '',
    products: productsString || '',
    volume: volume || '',
    company: company || '',
  });
}

export function setupGoogleAnalytics() {
  const clientId = getCookie('_gid');
  if (clientId) {
    googleAnalyticsSetUserProperties({ client_id: clientId });
  }

  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
    debug: process.env.REACT_APP_GA_DEBUG || true,
    gtagOptions: {
      transport_url: process.env.REACT_APP_GA_TRANSPORT_URL,
      first_party_collection: true,
    },
  });
}

function getProductsString(productArray) {
  return productArray.join(', ');
}

function getProductsIDs(productArray) {
  const automatic_subtitle_id = AUTOMATIC_SUBTITLE_ID;
  const manual_subtitles_id = MANUAL_SUBTITLES_ID;
  const translated_subtitle_id = TRANSLATED_SUBTITLE_ID;
  const automatic_transcription_id = AUTOMATIC_TRANSCRIPTION_ID;
  const manual_transcription_id = MANUAL_TRANSCRIPTION_ID;

  const idArray = productArray.map((product) =>
    // eslint-disable-next-line no-eval
    eval(`${product}_id`),
  );

  return idArray.join(', ');
}

function getSelectedProducts(products) {
  // get all products thats set to true
  const productsSelected = Object.entries(products).map((entry) => {
    if (entry[1] === true) return entry[0];
    return null;
  });
  const productArray = productsSelected.filter((entry) => entry !== null);

  return {
    productString: getProductsString(productArray),
    productIds: getProductsIDs(productArray),
  };
}

export function pushRegisterEventToDataLayer(onboardingData, email, language) {
  const dataLayer = window.dataLayer || [];

  const { productString, productIds } = getSelectedProducts(
    onboardingData.signupProducts,
  );

  const data = {
    event: 'sign_up',
    name: onboardingData.firstName,
    email,
    language,
    country_code: onboardingData.country,
    sector: onboardingData.sector,
    company: onboardingData.company,
    volume: onboardingData.signupVolume,
    volume_id: onboardingData.signupVolume,
    products: productString,
    product_ids: productIds,
  };

  dataLayer.push(data);
}

/**
 * Pushes an event to the data layer (NO GA4)
 * @param {object} data
 */
export function pushEventToDataLayer(data) {
  const dataLayer = window.dataLayer || [];
  dataLayer.push(data);
}

/**
 * Returns a string constant corresponding to the service type
 * @param {boolean} isAutomatic
 */
export function getServiceType(isAutomatic) {
  return isAutomatic ? GA_AUTOMATIC : GA_MANUAL;
}

/**
 * Returns a string constant corresponding to the service type
 * @param {string} serviceType
 */
export function getServiceTypeByString(serviceType) {
  if (serviceType === SERVICE_AUTOMATIC) {
    return GA_AUTOMATIC;
  }
  if (serviceType === SERVICE_MANUAL) {
    return GA_MANUAL;
  }
  return null;
}

/**
 * Returns a string constant corresponding to the product type
 * @param {boolean} translatedSubtitles
 * @param {boolean} isTranscription
 * @param {boolean} isSubtitle
 */
export function getProductType(
  translatedSubtitles,
  isTranscription,
  isSubtitle,
) {
  if (translatedSubtitles) {
    return GA_TRANSLATED_SUBTITLES;
  }
  if (isTranscription) {
    return GA_TRANSLATED_TRANSCRIPTION;
  }
  if (isSubtitle) {
    return GA_TRANSLATED_CAPTIONS;
  }
  return null;
}

/**
 * Pushes the upload_file event to the data layer (NO GA4)
 * @param {object} user
 * @param {string} formats
 */
export function fireUploadEvent(user, formats) {
  const { fullName, email, countryCode, sectorCode, analyticsId, language } =
    user;
  const { company } = user.billing;

  const data = {
    event: GA_EVENT_UPLOAD_FILE,
    name: fullName?.trim(),
    email,
    language,
    country_code: countryCode,
    sector: sectorCode || '',
    company: company || '',
    user_id: analyticsId,
    upload_format: formats,
  };

  pushEventToDataLayer(data);
}
/**
 * Pushes the export_file event to the data layer (NO GA4)
 * @param {object} user
 * @param {object} transcriptStatus
 * @param {string} format
 * @param {string} type
 * @param {string} product
 * @param {string} targetLanguage
 */
export function fireExportEvent(
  user,
  transcriptStatus,
  format,
  type,
  product,
  targetLanguage,
) {
  const { fullName, email, countryCode, sectorCode, analyticsId, language } =
    user;
  const { company } = user.billing;
  const { transcriptionType } = transcriptStatus.data;

  const data = {
    event: GA_EVENT_EXPORT_FILE,
    name: fullName?.trim(),
    email,
    language,
    country_code: countryCode,
    sector: sectorCode || '', // Can be undefined in some cases
    company,
    user_id: analyticsId,
    export_format: format.toLowerCase(),
    export_type: type,
    export_product: product || transcriptionType,
  };

  pushEventToDataLayer(data);
}

/**
 * Returns a string constant corresponding to the product type
 * @param {string} selectedService
 * @param {boolean} translated
 */
export function getSelectedServiceByString(selectedService, translated) {
  if (selectedService === SERVICE_TRANSCRIPTION) {
    return GA_TRANSLATED_TRANSCRIPTION;
  }
  if (selectedService === SERVICE_SUBTITLES) {
    if (translated) {
      return GA_TRANSLATED_JUST_SUBTITLES;
    }
    return GA_TRANSLATED_CAPTIONS;
  }
  return null;
}

/**
 * Returns an array containing the order options
 * @param {boolean} verbatim
 * @param {string} turnaroundTime
 */
export function getOrderOptions(verbatim, turnaroundTime) {
  const rushOrder =
    turnaroundTime === TURNAROUND_TIME_ONE_DAY ||
    turnaroundTime === TURNAROUND_TIME_THREE_DAYS;
  const options = [
    ...(verbatim ? [GA_OPTION_VERBATIM] : []),
    ...(rushOrder ? [GA_OPTION_RUSH] : []),
  ];
  return options.join('|');
}
