export const RECOIL_LOADABLE_HAS_VALUE = 'hasValue';
export const RECOIL_LOADABLE_LOADING = 'loading';
export const RECOIL_LOADABLE_HAS_ERROR = 'hasError';

// ******** COMMON ******** \\
export const NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS';
export const SELECTED_LANGUAGE = 'SELECTED_LANGUAGE';
export const COUNTRY_DATA = 'COUNTRY_DATA';
export const REFRESH_TABLE_GENERATOR_DATA = 'REFRESH_TABLE_GENERATOR_DATA';
export const UNSUBSCRIBE_PLAN = 'UNSUBSCRIBE_PLAN';

// ******** LOGIN / REGISTER ******** \\
export const REGISTER_ONBOARDING = 'REGISTER_ONBOARDING';
export const REGISTER_COUNTRY_SECTOR = 'REGISTER_COUNTRY_SECTOR';
export const GET_SECTOR = 'GET_SECTOR';

// ******** USER ******** \\
export const USER_DATA = 'USER_DATA';
export const USER_DATA_ATOM = 'USER_DATA_ATOM';

// ******** USER SUBSCRIBED ******** \\
export const USER_SUBSCRIBED = 'USER_SUBSCRIBED';

// ******** USER TRANSCRIBER ******** \\
export const USER_TRANSCRIBER = 'USER_TRANSCRIBER';

// ******** USER RECORD ******** \\
export const USER_RECORD_REMOVE_FILE = 'USER_RECORD_REMOVE_FILE';

// ******** ADMIN USER ******** \\
export const ADMIN_USER_REMOVE_USER = 'ADMIN_USER_REMOVE_USER';
export const ADMIN_USER_NAV_TAB = 'ADMIN_USER_NAV_TAB';
export const ADMIN_USER_RECORD_REFRESH = 'ADMIN_USER_RECORD_REFRESH';
export const ADMIN_USER_RECORD_ID = 'ADMIN_USER_RECORD_ID';
export const ADMIN_USER_REOPEN_RECORD = 'ADMIN_USER_REOPEN_RECORD';

// ******** Organization ******** \\
export const ADMIN_REMOVE_ORGANIZATION = 'ADMIN_REMOVE_ORGANIZATION';

// ******** KALTURA - STATUSSES ******** \\
export const KALTURA_ERROR = 'KALTURA_ERROR';
export const AMBERSCRIPT_TRANSLATION_PROCESSING =
  'AMBERSCRIPT_TRANSLATION_PROCESSING';
export const KALTURA_PROCESSING = 'KALTURA_PROCESSING';
export const KALTURA_READY = 'KALTURA_READY';
export const KALTURA_API_ERROR = 'KALTURA_API_ERROR';
export const AMBERSCRIPT_API_ERROR = 'AMBERSCRIPT_API_ERROR';
export const KS_NEW = 'KS_NEW';
export const KS_READY_TO_FETCH_KALTURA_ASSET =
  'KS_READY_TO_FETCH_KALTURA_ASSET';
export const KS_FETCHING_ASSET = 'KS_FETCHING_ASSET';
export const AMBERSCRIPT_TRANSCRIPTION_PROCESSING =
  'AMBERSCRIPT_TRANSCRIPTION_PROCESSING';
export const AMBERSCRIPT_API_DELETE_ERROR = 'AMBERSCRIPT_API_DELETE_ERROR';
export const AMBERSCRIPT_DONE = 'AMBERSCRIPT_DONE';
export const TO_UPDATE_KALTURA_WITH_ERROR = 'TO_UPDATE_KALTURA_WITH_ERROR';

// ******** KALTURA - ATOM ******** \\
export const ADMIN_VIEW_KALTURA_TASK_ATOM = 'ADMIN_VIEW_KALTURA_TASK_ATOM';

// ******** KALTURA - ENDPOINTS ******** \\
export const KALTURA_ENDPOINTS = [
  'https://www.kaltura.com/',
  'https://api.eu.kaltura.com/',
  'http://kmc.l2l.cineca.it/',
  'https://api.kaltura.nordu.net/',
  'https://api.ca.kaltura.com/',
];

// ******** GLOSSARY ******** \\
export const GLOSSARY_DATA = 'GLOSSARY_DATA';
export const GLOSSARY_DIALOG = 'GLOSSARY_DIALOG';
export const GLOSSARY_REFRESH = 'GLOSSARY_REFRESH';
export const GLOSSARY_CHECKOUT_ERROR = 'GLOSSARY_CHECKOUT_ERROR';
export const GLOSSARY_CHECKBOX = 'GLOSSARY_CHECKBOX';
export const GLOSSARY_CURRENT_BATCH_DETAIL = 'GLOSSARY_CURRENT_BATCH_DETAIL';

// ******** REDEEM COUPON ******** \\
export const REDEEM_COUPON_DIALOG_ATOM = 'REDEEM_COUPON_DIALOG_ATOM';

// ******** REFERRAL COUPON ******** \\
export const REFERRAL_DIALOG_ATOM = 'REFERRAL_DIALOG_ATOM';

// ******** UPPY DIALOG ******** \\
export const UPPY_DIALOG_ATOM = 'UPPY_DIALOG_ATOM';

// ******** SALES PERSON ******** \\
export const SALESPERSON_ATOM = 'SALESPERSON_ATOM';

// ******** FIREBASE CLOUD MESSAGING ******** \\
export const FCM_TOKEN = 'FCM_TOKEN';
export const FCM_OPEN_DIALOG = 'FCM_OPEN_DIALOG';
export const FCM_MESSAGE_PAYLOAD = 'FCM_MESSAGE_PAYLOAD';

// ******** BURN IN ******** \\
export const BURN_IN_TRANSLATED_LANGUAGES = 'BURN_IN_TRANSLATED_LANGUAGES';
export const BURN_IN_JOB_SELECTED = 'BURN_IN_JOB_SELECTED';

// ******** JOB SATISFACTION ******** \\
export const JOB_SATISFACTION_OPEN_DIALOG = 'JOB_SATISFACTION_OPEN_DIALOG';

// ******** EMAIL NOTIFICATION ******** \\
export const EMAIL_NOTIFICATION_OPEN = 'EMAIL_NOTIFICATION_OPEN';
