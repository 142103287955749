import { ANALYTICS_CLIENT_ID, ANALYTICS_ID } from './constants';
import { removeToken } from './storage';

export default function handlePostLogout() {
  // Remove tokens
  removeToken('amber.user_type');
  removeToken('amber.analyticsDL');
  removeToken('amber.user_name');
  removeToken('amber.user');
  removeToken('amber.key');
  removeToken('amber.showReferralPopupAfterRegister');
  removeToken('amber.accessToken');
  removeToken('amber.refreshToken');

  removeToken(ANALYTICS_CLIENT_ID);
  removeToken(ANALYTICS_ID);
}
