import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import LoadingPage from './LoadingPage';

import { getUserSelector } from '../store/user';
import { getCountrySelector } from '../store/common';
import {
  RECOIL_LOADABLE_HAS_VALUE,
  RECOIL_LOADABLE_LOADING,
  RECOIL_LOADABLE_HAS_ERROR,
} from '../store/constants';

export default function PrivateRoute(props) {
  const { component: CustomComponent } = props;

  function navigateUserToLoginPage() {
    const location = useLocation();
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const userLoadable = useRecoilValueLoadable(getUserSelector);
  const countryLoadable = useRecoilValueLoadable(getCountrySelector);

  if (
    userLoadable.state === RECOIL_LOADABLE_HAS_VALUE &&
    countryLoadable.state === RECOIL_LOADABLE_HAS_VALUE
  ) {
    return <CustomComponent />;
  }
  if (
    userLoadable.state === RECOIL_LOADABLE_LOADING ||
    countryLoadable.state === RECOIL_LOADABLE_LOADING
  ) {
    return <LoadingPage />;
  }
  if (
    userLoadable.state === RECOIL_LOADABLE_HAS_ERROR ||
    countryLoadable.state === RECOIL_LOADABLE_HAS_ERROR
  ) {
    return navigateUserToLoginPage();
  }
  return navigateUserToLoginPage();
}

PrivateRoute.propTypes = {
  component: PropTypes.shape().isRequired,
};
