import { createTheme } from '@mui/material/styles';

import AMBER_FONTS from './fonts';
import {
  AMBER_PRIMARY,
  AMBER_SECONDARY,
  AMBER_RED_COLOR,
  AMBER_INFO_GRAY,
  PIG_PINK,
  CHARCOAL_GRAY,
  AMBER_LIGHT_GRAY,
  DARK_GREEN_COLOR,
  GREEN_COLOR,
  GRAY_COLOR,
  LIGHT_RED,
  LIGHT_YELLOW,
  LIGHT_GREEN,
  HOVER_DARK_GREEN,
  HOVER_LIGHT_GREEN,
  AMBER_SUNFLOWER_YELLOW,
} from './colors';

// Material UI default theme
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `${AMBER_FONTS}`,
    },
  },
  typography: {
    fontFamily: 'Geomanist, sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: AMBER_PRIMARY,
    },
    secondary: {
      main: AMBER_SECONDARY,
    },
    error: {
      main: AMBER_RED_COLOR,
      secondary: LIGHT_RED,
    },
    success: {
      main: GREEN_COLOR,
    },
    table: {
      headerBackground: '#F5F5F5',
    },
    custom: {
      charcoalGray: CHARCOAL_GRAY,
      infoMessage: AMBER_INFO_GRAY,
      gray: GRAY_COLOR,
      lightGray: AMBER_LIGHT_GRAY,
      darkGreenColor: DARK_GREEN_COLOR,
      pigPink: PIG_PINK,
      lightRed: LIGHT_RED,
      lightYellow: LIGHT_YELLOW,
      lightGreen: LIGHT_GREEN,
      hoverDarkGreen: HOVER_DARK_GREEN,
      hoverLightGreen: HOVER_LIGHT_GREEN,
      sunflowerYellow: AMBER_SUNFLOWER_YELLOW,
    },
  },
  spacing: 4,
  fontSizes: {
    small: {
      xs: '0.7rem',
      sm: '0.7rem',
      md: '0.8rem',
      lg: '0.8rem',
      xl: '0.9rem',
    },
    normal: {
      xs: '0.8rem',
      sm: '0.8rem',
      md: '0.9rem',
      lg: '0.9rem',
      xl: '1rem',
    },
    big: {
      xs: '0.9rem',
      sm: '0.9rem',
      md: '1rem',
      lg: '1rem',
      xl: '1.1rem',
    },
    header: {
      xs: '1rem',
      sm: '1rem',
      md: '1.2rem',
      lg: '1.2rem',
      xl: '1.5rem',
    },
    button: {
      xs: '0.9rem',
      sm: '0.9rem',
      md: '1rem',
      lg: '1rem',
      xl: '1.1rem',
    },
  },
});

theme.typography.small = {
  fontFamily: 'Geomanist, sans-serif',
  fontWeight: 400,
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.fontSizes.small.xs,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: theme.fontSizes.small.xs,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: theme.fontSizes.small.sm,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: theme.fontSizes.small.md,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: theme.fontSizes.small.lg,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.fontSizes.small.xl,
  },
};

theme.typography.smallBold = {
  fontFamily: 'Geomanist-Bold, sans-serif',
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.fontSizes.small.xs,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: theme.fontSizes.small.xs,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: theme.fontSizes.small.sm,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: theme.fontSizes.small.md,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: theme.fontSizes.small.lg,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.fontSizes.small.xl,
  },
};

theme.typography.normal = {
  fontFamily: 'Geomanist, sans-serif',
  fontWeight: 400,
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.fontSizes.normal.xs,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: theme.fontSizes.normal.xs,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: theme.fontSizes.normal.sm,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: theme.fontSizes.normal.md,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: theme.fontSizes.normal.lg,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.fontSizes.normal.xl,
  },
};

theme.typography.normalBold = {
  fontFamily: 'Geomanist-Bold, sans-serif',
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.fontSizes.normal.xs,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: theme.fontSizes.normal.xs,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: theme.fontSizes.normal.sm,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: theme.fontSizes.normal.md,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: theme.fontSizes.normal.lg,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.fontSizes.normal.xl,
  },
};

theme.typography.bigBold = {
  fontFamily: 'Geomanist-Bold, sans-serif',
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.fontSizes.big.xs,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: theme.fontSizes.big.xs,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: theme.fontSizes.big.sm,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: theme.fontSizes.big.md,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: theme.fontSizes.big.lg,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.fontSizes.big.xl,
  },
};

theme.typography.big = {
  fontFamily: 'Geomanist, sans-serif',
  fontWeight: 400,
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.fontSizes.big.xs,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: theme.fontSizes.big.xs,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: theme.fontSizes.big.sm,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: theme.fontSizes.big.md,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: theme.fontSizes.big.lg,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.fontSizes.big.xl,
  },
};

theme.typography.headerNormal = {
  fontFamily: 'Geomanist, sans-serif',
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.fontSizes.header.xs,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: theme.fontSizes.header.xs,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: theme.fontSizes.header.sm,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: theme.fontSizes.header.md,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: theme.fontSizes.header.lg,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.fontSizes.header.xl,
  },
};

theme.typography.header = {
  fontFamily: 'Geomanist-Bold, sans-serif',
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.fontSizes.header.xs,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: theme.fontSizes.header.xs,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: theme.fontSizes.header.sm,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: theme.fontSizes.header.md,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: theme.fontSizes.header.lg,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.fontSizes.header.xl,
  },
};

theme.typography.button = {
  fontFamily: 'Geomanist-Medium, sans-serif',
  fontWeight: 400,
  [theme.breakpoints.down('xs')]: {
    fontSize: theme.fontSizes.button.xs,
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    fontSize: theme.fontSizes.button.xs,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: theme.fontSizes.button.sm,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: theme.fontSizes.button.md,
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    fontSize: theme.fontSizes.button.lg,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: theme.fontSizes.button.xl,
  },
};

export default theme;
