import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithRedirect,
  inMemoryPersistence,
  SAMLAuthProvider,
  getRedirectResult,
  signOut,
} from 'firebase/auth';
import apiClient from '../api/apiClient';
import SetupProfitwell from './profitwell';
import { createSamlSessionCookie } from '../api/saml';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FAUTH_API_KEY,
  authDomain: process.env.REACT_APP_FAUTH_AUTH_DOMAIN,
};

function doPostLoginProcess(username) {
  // Setup Profitwell
  SetupProfitwell(username);
}

export async function setupFirebaseAuthentication() {
  initializeApp(firebaseConfig);
  await getAuth().setPersistence(inMemoryPersistence);
}

export async function signInWithEmailPassword(username, password) {
  try {
    const auth = getAuth();

    const userCredential = await signInWithEmailAndPassword(
      auth,
      username,
      password,
    );
    const { user } = userCredential;
    if (user) {
      const idToken = await user.getIdToken();

      const formData = new FormData();
      formData.append('idToken', idToken);

      await apiClient.post('session/login', formData, {
        'Content-Type': 'multipart/form-data',
        withCredentials: true,
      });

      await signOut(auth);
      doPostLoginProcess(username);
      return Promise.resolve();
    }
    return Promise.resolve(null);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function signInWithRedirectToProvider(providerId) {
  const auth = getAuth();
  const provider = new SAMLAuthProvider(providerId);
  await signInWithRedirect(auth, provider);
}

export async function getProviderRedirectResult(organizationParams, email) {
  try {
    const auth = getAuth();
    const userCredential = await getRedirectResult(auth);
    if (userCredential) {
      const { user } = userCredential;
      if (user) {
        const idToken = await user.getIdToken();
        await createSamlSessionCookie(idToken, organizationParams, email);

        await signOut(auth);
        if (user.email) {
          doPostLoginProcess(user.email);
        }
        return Promise.resolve();
      }
    }
    return Promise.resolve(null);
  } catch (error) {
    return Promise.reject(error);
  }
}
