import {
  USER_ROLE_REAL,
  USER_ROLE_ADMIN,
  USER_ROLE_ORG_ADMIN,
  USER_ROLE_ORG_TEAM_ADMIN,
  USER_ROLE_ORG_TEAM_MEMBER,
  USER_ROLE_TRANSCRIBER,
  USER_ROLE_PERFECT_JOB_MANAGER,
  USER_ROLE_QUALITY_CONTROLLER,
} from './constants';

// Deprecated, there are no 'REAL' roles in BE
export function isReal(user) {
  return (
    user && user.roles && user.roles.some((item) => item === USER_ROLE_REAL)
  );
}

export function isAdmin(user) {
  return (
    user && user.roles && user.roles.some((item) => item === USER_ROLE_ADMIN)
  );
}

export function isOrgAdmin(user) {
  return (
    user &&
    user.roles &&
    user.roles.some((item) => item === USER_ROLE_ORG_ADMIN)
  );
}

export function isOrgTeamAdmin(user) {
  return (
    user &&
    user.roles &&
    user.roles.some((item) => item === USER_ROLE_ORG_TEAM_ADMIN)
  );
}

export function isOrgTeamMember(user) {
  return (
    user &&
    user.roles &&
    user.roles.some((item) => item === USER_ROLE_ORG_TEAM_MEMBER)
  );
}

export function isTranscriber(user) {
  return (
    user &&
    user.roles &&
    user.roles.some((item) => item === USER_ROLE_TRANSCRIBER)
  );
}

export function isPerfectJobManager(user) {
  return (
    user &&
    user.roles &&
    user.roles.some((item) => item === USER_ROLE_PERFECT_JOB_MANAGER)
  );
}

export function isQualityController(user) {
  return (
    user &&
    user.roles &&
    user.roles.some((item) => item === USER_ROLE_QUALITY_CONTROLLER)
  );
}

export function hasNoRole(user) {
  return user && user.roles && !user.roles.length;
}
