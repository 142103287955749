import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

export default function AmberCircularLoading(props) {
  const { size = 40 } = props;

  return <CircularProgress size={size} thickness={1.5} disableShrink />;
}

AmberCircularLoading.propTypes = {
  size: PropTypes.number,
};

AmberCircularLoading.defaultProps = {
  size: 40,
};
