import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';

import LoadingPage from './LoadingPage';

import { getUserSelector } from '../store/user';
import { getCountrySelector } from '../store/common';
import {
  RECOIL_LOADABLE_HAS_ERROR,
  RECOIL_LOADABLE_HAS_VALUE,
  RECOIL_LOADABLE_LOADING,
} from '../store/constants';
import { isAdmin } from '../helpers/roles';

export default function PrivateRouteAdmin(props) {
  const { component: CustomComponent } = props;
  const userLoadable = useRecoilValueLoadable(getUserSelector);
  const countryLoadable = useRecoilValueLoadable(getCountrySelector);

  function navigateUserToLoginPage() {
    const location = useLocation();
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  function navigateUserToRecordsPage() {
    return window.location.replace('/records');
  }

  function isAdminUser() {
    return (
      userLoadable &&
      userLoadable.contents &&
      userLoadable.contents.data &&
      isAdmin(userLoadable.contents.data)
    );
  }

  if (
    userLoadable.state === RECOIL_LOADABLE_HAS_VALUE &&
    countryLoadable.state === RECOIL_LOADABLE_HAS_VALUE
  ) {
    if (isAdminUser()) {
      return <CustomComponent />;
    }
    return navigateUserToRecordsPage();
  }
  if (
    userLoadable.state === RECOIL_LOADABLE_LOADING ||
    countryLoadable.state === RECOIL_LOADABLE_LOADING
  ) {
    return <LoadingPage />;
  }
  if (
    userLoadable.state === RECOIL_LOADABLE_HAS_ERROR ||
    countryLoadable.state === RECOIL_LOADABLE_HAS_ERROR
  ) {
    return navigateUserToLoginPage();
  }
  return navigateUserToLoginPage();
}

PrivateRouteAdmin.propTypes = {
  component: PropTypes.shape().isRequired,
};
