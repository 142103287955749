import axios from 'axios';

/**
 * Get available IDPs by query
 * @param {*} query query to search
 * @param {AxiosCancelToken} cancelToken cancelToken from axios to cancel the current API call
 * @returns List of IDPs by query
 */
// eslint-disable-next-line import/prefer-default-export
export function getIdpsByQuery(query, cancelToken) {
  const baseURL = process.env.REACT_APP_SAML_BE_BASE_URL;
  const reqUrl = `${baseURL}/idp-entity-descriptors?displayName=${query}`;
  return axios.get(
    reqUrl,
    {},
    {
      cancelToken: cancelToken.token,
    },
  );
}

/**
 * Get accessToken by refreshToken
 * @param {String} refreshToken refreshToken
 * @returns Promise
 */
export function getAccessTokenByRefreshToken() {
  const baseURL = `${process.env.REACT_APP_BE_BASE_URL}/v2/`;

  return axios.post(
    `${baseURL}refresh-token`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );
}

export function createSamlSessionCookie(idToken, organizationParams, email) {
  const baseURL = process.env.REACT_APP_SAML_BE_BASE_URL;
  const reqUrl = `${baseURL}/firebase-login?${organizationParams}${
    email ? `&email=${encodeURIComponent(email)}` : ''
  }`;
  const formData = new FormData();
  formData.append('idToken', idToken);
  return axios.post(reqUrl, formData, {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
}

export function registerUser(payload, organizationId, organizationName) {
  const baseURL = process.env.REACT_APP_SAML_BE_BASE_URL;
  const url = `${baseURL}/register`;
  return axios.post(url, payload, {
    params: {
      organizationId,
      organizationName,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
}
